.navigationLoader {
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    color: var(--primary-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  h6 {
    margin: 20px 0px;
    text-align: center;
    width: 100%;
  }
}
