@import './fonts';
@import './colors';

.modal {
  background: rgba($color: var(--informative-text), $alpha: 0.4);
  .modal-content {
    border: 1px solid var(--informative-text);
    overflow: hidden;
    border-radius: 4px;
    .modal-body,
    .modal-footer {
      padding: 25px 30px;
    }
    .modal-footer {
      border-bottom: 1px solid var(--informative-text);
      & > * {
        margin: 0;
      }
    }
  }
}
//buttons
.btn {
  height: 40px;
  border-radius: 5px;
  @include setFont('medium', 13);
}
.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  svg {
    .animation,
    .animate {
      fill: white !important;
    }
  }
}

.btn-outline-primary {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
  svg {
    .animation,
    .animate {
      fill: var(--primary-color) !important;
    }
  }
  &:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
  }
}
.btn-danger {
  background-color: var(--light-red) !important;
  border-color: var(--light-red) !important;
  svg {
    .animation {
      fill: white !important;
    }
  }
}

.btn-outline-success {
  svg {
    .animation,
    .animate {
      fill: var(--bs-success) !important;
    }
  }

  &:hover {
    svg {
      .animation,
      .animate {
        fill: white !important;
      }
    }
  }
}

.btn-outline-danger {
  border-color: var(--light-red) !important;
  color: var(--light-red) !important;
  svg {
    .animation,
    .animate {
      fill: var(--light-red) !important;
    }
  }
  &:hover {
    background-color: var(--light-red) !important;
  }
}
.btn-outline-primary,
.btn-outline-danger {
  &:hover {
    color: white !important;
    svg {
      .animation,
      .animate {
        fill: white !important;
      }
    }
  }
}
.btn,
input,
textarea,
select {
  box-shadow: none !important;
}
.form-check {
  display: flex;
  padding-inline-start: 1.25rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  .form-check-input {
    margin: 0;
    width: 16px;
    height: 16px;
    position: relative;
    margin-inline-end: 10px;
  }
  .form-check-label {
    width: calc(100% - 26px);
  }
}

.row:not(.no-gutters) {
  margin-left: -10px;
  margin-right: -10px;
  [class*='col-'],
  .col {
    padding: 0 10px;
  }
}
