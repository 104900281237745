@import 'assets/styles/colors';
@import 'assets/styles/fonts';

* {
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}
.react-datepicker {
  display: flex !important;
  @include setFont('medium', 13);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Poppins-Black';
  src: url('./assets/fonts/Poppins/Poppins-Black.ttf');
}

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url('./assets/fonts/Poppins/Poppins-BlackItalic.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url('./assets/fonts/Poppins/Poppins-BoldItalic.ttf');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url('./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('./assets/fonts/Poppins/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url('./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url('./assets/fonts/Poppins/Poppins-Italic.ttf');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url('./assets/fonts/Poppins/Poppins-LightItalic.ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url('./assets/fonts/Poppins/Poppins-MediumItalic.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url('./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('./assets/fonts/Poppins/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url('./assets/fonts/Poppins/Poppins-ThinItalic.ttf');
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

* {
  outline: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--tint-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--tint-dark-grey);
}

input[type='text'],
input[type='email'],
select,
.dropdown-container,
.autoComplete .field {
  height: 40px !important;
  @include setFont('regular', 13);
}
.menu-outer-top .Select-menu-outer {
  bottom: 35px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
.react-select,
textarea {
  @include setFont('regular', 13);
}
.react-select input {
  height: 100% !important;
}
.autoComplete *:not(label) {
  @include setFont('regular', 13);
}
select {
  border: 1px solid var(--secondary-color) !important;
  text-transform: capitalize !important;
}
input::-webkit-input-placeholder {
  @include setFont('regular', 13);
  text-transform: capitalize !important;
}
.custom-select,
select {
  padding: 0.375rem 2rem 0.375rem 0.75rem !important;
  text-overflow: ellipsis;
  background: var(--color-white) url('./assets/icons/arrow-down.svg') no-repeat right 0.75rem
    center/15px 15px !important;
}
body[dir='rtl'] {
  text-align: right;
  svg:not(.no-rotate):not(.highcharts-root):not(.jodit-icon):not(.navigation-link>span>svg),
  img:not(.no-rotate):not(.highcharts-root):not(.jodit-icon):not(.navigation-link>span>img) {
    transform: scaleX(-1);
  }


  .password-strength {
    .bar {
      transform: scale(-1);
    }
  }
  .custom-select,
  select {
    padding: 0.375rem 0.75rem 0.375rem 2rem !important;
    background: var(--color-white) url('./assets/icons/arrow-down.svg') no-repeat left 0.75rem
      center/15px 15px !important;
  }
  .date .sub-title {
    direction: ltr;
  }
  .notification__item .notification__close {
    right: calc(100% - 20px);
  }
}
.custom-checkbox {
  margin-bottom: 15px;
}         
.custom-checkbox:last-of-type:not(.filterCheckbox):not(.role-checkbox),
.form-group:last-of-type:not(.filterCheckbox):not(.role-checkbox) {
  margin: 0;
}
input:-webkit-autofill {
  // background-clip: text;
  // -webkit-background-clip: text;
}
@media (max-width: 768px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}

.animated {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.notification__item .notification__content {
  padding: 8px !important;
}
.notification__info,
.notification__info .timer,
.notification__info .notification__close {
  background-color: var(--primary-color) !important;
}
.notification__item .notification__close {
  top: 0 !important;
  bottom: 0;
  margin: auto 0;
}

.notification-message {
  @include setFont('regular', 13);
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
.password-strength {
  span {
    @include setFont('regular', 13);
  }
  .bar {
    width: 100%;
    position: relative;
    background-color: var(--secondary-color) !important;
    height: 4px;
    overflow: hidden;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      transition: all 0.5s;
      content: '';
    }
    &.veryLow:after {
      background-color: red;
      width: 25%;
    }
    &.low:after {
      background-color: yellow;
      width: 50%;
    }
    &.strong:after {
      background-color: orange;
      width: 75%;
    }
    &.veryStrong:after {
      background-color: var(--green);
      width: 100%;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.p-end-0 {
  padding-inline-end: 0 !important;
}
.p-start-0 {
  padding-inline-start: 0 !important;
}
svg {
  .animate {
    transition: all 0.15s;
  }
}

.country-flag {
  @include setFont('regular', 13);
  display: flex;
  margin-inline-end: 10px;
  align-items: center;
  img {
    max-width: 40px !important;
    height: 30px !important;
  }
}

.no-scroll {
  overflow: hidden;
}
.hover-tooltip {
  position: relative;
}
