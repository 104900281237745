@import 'assets/styles/fonts';

.action {
  vertical-align: middle;
  margin-inline-start: auto;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    width: 16px;
    height: 16px;
    transform: scale(1) !important;
    .animate {
      fill: white;
    }
  }
  span {
    @include setFont('regular', 13);
    padding-inline-start: 5px;
    line-height: 1;
  }
}
