@use 'sass:math';

$bold: 'Poppins-Bold', sans-serif;
$medium: 'Poppins-Medium', sans-serif;
$regular: 'Poppins-Regular', sans-serif;
$semiBold: 'Poppins-SemiBold', sans-serif;

/* font size & line height in px */

$font-size-body-px: 16;
$line-height-px: 21;

@mixin setFont($font, $size: 0) {
  @if $size!=0 {
    font-size: #{math.div($size, 16)}rem !important;
  }
  @if $font == 'medium' {
    font-family: $medium !important;
  }
  @if $font == 'bold' {
    font-family: $bold !important;
  }
  @if $font == 'regular' {
    font-family: $regular !important;
  }

  @if $font == 'semiBold' {
    font-family: $semiBold !important;
  }
}
