$lineLightGrey: #ebedf3 !default;
$boxShadow: 0 0 30px 0 rgba(82, 63, 105, 0.03);
$lineSeperatorLightGrey: #ebedf3 !default;
$tintGreen: #16c5bd;
$secondaryColor: #e4e6ef;

:root {
  --seperator-line-grey: #ebedf3;
  --secondary-color: #e4e6ef;
  --primary-color: #0c58af;
  --dark-color: #1e1d2d;
  --light-grey: #b9bdd5;
  --medium-grey: #ccc;
  --dark-grey: #3f4254;
  --informative-text: #9ea2b9;
  --non-authenticated-layout-bg: #f9fafc;
  --submit: #00ac74;
  --green: #1ed88f;
  --light-red: #fe3535;
  --blue: #4f99fe;
  --color-black: #000000;
  --color-white: #ffffff;
  --tint-dark-grey: #555;
  --tint-light-grey: #f6f9fc;
  --tint-white: #f1f1f1;
  --tint-green: #16c5bd;
  --tint-red: #c5161e;
  --tint-orange: #ffa800;
  --gray-2: #edeff7;
  --table-header: rgba(0, 85, 170, 0.05);
  --row-border: #e1e4f0;
  --tabs-border: #d6d9df;
  --nav-point-color: #f4f6f9;
  --profile-title: #c1c6db;
  --blue-icon: #3599fe;
  --generate: #d0a453;
  --kuwait-green: #73af00;
  --kuwait-red: #ff4b55;
  --kuwait-white: #f5f5f5;
  --kuwait-black: #464655;
  --noData-grey: #b3b3b3;
  --noData-white: #f2f2f2;
  --noData-blue: #0260ae;
  --usa-blue: #41479b;
  --side-menu-link: #1b72d7;
  --side-menu-nav-title: #757575;
}
