@import "~bootstrap/scss/bootstrap";
@import "assets/styles/bootstrap-override.scss";
@import "assets/styles/fonts";
@import "assets/styles/width";
@import "assets/styles/colors";

body,
html {
  width: 100% !important;
  height: 100% !important;
}
h1,
h2,
h3,
h4,
h6 {
  @include setFont("bold");
}
h5 {
  @include setFont("bold", 18);
}

#root {
  width: 100%;
  height: 100%;
}

#root:first-child {
  width: 100%;
  height: 100%;
}
.App {
  padding-top: 20px;
  height: 100%;
}
label {
  @include setFont("medium", 13);
}

table a {
  color: var(--color-black) !important;
  text-decoration: none !important;
}
.table-header {
  padding: 0 30px 18px 30px;
  margin: 0 !important;
  align-items: center;

  h5 {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    align-items: center;
    padding: 0 15px 18px 15px !important;
  }
}
.image-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba($color: var(--informative-text), $alpha: 0.5);
}

.errorBorder {
  border: 1px solid var(--light-red) !important;
  border-radius: 5px;
}
.errorMessage {
  @include setFont("regular", 12);
}
.chart-container {
  padding: 25px 30px;
  background-color: var(--color-white);
  border-radius: 10px;
  margin-bottom: 20px;
}
.analytics-filter {
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  width: 100%;
  .form-group {
    margin-bottom: 0;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
}

.nodatafound {
  padding: 30px 20px;
  flex-wrap: wrap;
  min-height: 300px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.03);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    margin: 0 auto;
  }
  h6 {
    width: 100%;
    text-align: center;
    color: var(--primary-color);
    margin-top: 30px;
  }
}

.audioBooks-table {
  .article-title {
    .img {
      width: 44px;
      height: 64px;
      img:not([alt="placeholder"]) {
        height: 100% !important;
        max-width: initial !important;
        width: auto !important;
      }
    }
  }
}

//commons
.genres-view {
  .genres-headers {
    display: flex;
    align-items: center;
    margin-inline-start: auto;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
      display: block;
      .svg-button {
        width: 100%;
        margin-top: 15px !important;
      }
    }
  }
  .add-genre {
    .checkboxes-container {
      p {
        margin-bottom: 0;
      }
      label:not(.switch) {
        margin-bottom: 10px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--informative-text);
        padding-bottom: 25px;
        margin-bottom: 25px;
      }
      .custom-checkbox {
        margin-bottom: 0 !important;
        width: 50%;
      }
    }
    .svg-button {
      margin-top: 20px;
      width: 100%;
    }
  }
}

.wizard {
  padding: 20px 30px;
  background: var(--color-white);
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.03);

  @media (max-width: 1024px) {
    margin-bottom: 15px;
    padding: 15px;
  }
  .progressElement-container:not(:first-child) {
    border-left: 1px solid var(--informative-text);
  }
}
body[dir="rtl"] {
  .wizard {
    .progressElement-container:not(:first-child) {
      border-right: 1px solid var(--informative-text);
      border-left: none;
    }
  }
}
@media (max-width: 1024px) {
  .progressElement-container {
    border-right: none !important;
    border-left: none !important;
  }
}

.actions {
  display: flex;
  align-items: center;
  .svg-button {
    padding: 0 !important;
    height: auto;
  }
}

.hint {
  @include setFont("regular", 12);
  color: var(--informative-text);
}

.manage-item-outer-container {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;

  .manage-item-inner-container {
    width: 100%;
    display: flex;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    align-items: center;
    background-color: transparent;

    @media (max-width: 480px) {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    .manage-item-info-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-inline-start: 20px;
      margin-inline-end: 15px;
      align-items: center;

      .manage-item-name {
        @include setFont("regular", 12);
        color: var(--color-black);
        text-transform: capitalize;
      }

      .manage-item-type {
        @include setFont("regular", 12);
        color: var(--color-black);
        margin-inline-end: 5px;
        text-transform: capitalize;
      }

      @media (max-width: 480px) {
        margin-inline-start: 10px;
      }
    }

    .action-icon {
      cursor: pointer;
    }
  }

  .child-inner-container {
    background-color: var(--tint-light-grey);
  }
}

.child-outer-container {
  padding-inline-start: 40px;
}

.environment {
  border-radius: 5px;
  @include setFont("regular", 12);
  text-transform: uppercase;
  color: var(--color-white);
  padding: 0 5px;
  opacity: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  animation-name: fadeLeftIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1s;

  display: flex;
  align-items: center;

  .version {
    margin-inline-start: 5px;
  }

  @media (max-width: 410px) {
    display: none;
  }

  &.dev,
  &.local {
    background-color: var(--light-red);
  }

  &.uat {
    background-color: var(--tint-orange);
  }

  &.prod {
    background-color: var(--primary-color);
  }
}

.btn-success {
  background-color: var(--submit) !important;
  border-color: var(--submit) !important;
  svg {
    .animation,
    .animate {
      fill: var(--color-white) !important;
    }
  }
}
.dashboard-card-content {
  height: 100%;
  padding-bottom: 20px;

  .dashboard-card {
    background-color: white;
    border-radius: 10px;
    height: 100%;
    text-decoration: none !important;
    padding: 20px;
    display: flex;
    align-items: center;

    img {
      margin-inline-end: 15px;
    }

    .title {
      color: black;
      font-family: "Poppins-Bold", "AlQabas-Bold", sans-serif !important;
      font-size: 1rem;
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .widget-info {
      flex-grow: 1;

      .subTitle {
        margin-bottom: 0;
        color: var(--informative-text);
        @include setFont("medium", 12);
      }
    }
  }
}

.form-control {
  height: 40px;
  border-radius: 5px;
  border: solid 1px var(--secondary-color);
  background-color: var(--color-white);
  color: var(--dark-grey);
  font-size: 13px;
  &:hover {
    border: solid 1px var(--secondary-color);
  }
}

.form-control input ::-webkit-input-placeholder {
  color: var(--informative-text) !important;
}

textarea.form-control {
  height: unset;
}

.form-text {
  @include setFont("regular", 13);
}

.comment-sideBar {
  padding: 30px 0px;
  position: relative;
  .btn {
    @include setFont("medium", 13);
  }
  .divider {
    height: 5px;
    background-color: var(--gray-2);
    width: calc(100% + 60px);
    position: absolute;
    left: -30px;
    bottom: 0;
  }
  .comment-header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .comment-image {
      background-color: var(--tint-light-grey);
      width: 40px;
      border-radius: 5px;
      height: 40px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    .title-subtitle {
      padding-inline-start: 20px;
      flex-grow: 1;
      .title {
        @include setFont("regular", 14);
        margin-bottom: 0;
        text-transform: capitalize;
      }
      .subTitle {
        @include setFont("regular", 13);
        color: var(--informative-text);
        margin-bottom: 0;
      }
      .email {
        margin-bottom: 0;
        @include setFont("regular", 13);
        color: var(--informative-text);
      }
    }
  }
  .highLighted-text {
    text-align: right;
    @include setFont("regular", 14);
    margin-bottom: 20px;
  }
  span {
    @include setFont("regular", 13);
    &.comment-title {
      margin-bottom: 7px;
      display: block;
    }
  }
}

.danger-publish {
  @include setFont("medium", 13);
  margin-top: 10px;
  color: var(--light-red);
}

.margin-top-small {
  margin-top: 10px;
}

.svg-button.create-button {
  background-color: var(--submit);
  color: var(--color-white);
  border: none;
  outline: none;

  &:hover {
    color: var(--color-white);
  }
}

.cursor-default {
  cursor: default !important;
}
