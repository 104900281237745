@import 'assets/styles/fonts';

.button {
  border-color: var(--primary-color);
  color: var(--primary-color);

  &:focus {
    background-color: var(--primary-color);
    color: var(--color-white);
    .leftIcon {
      filter: sepia(1) hue-rotate(200deg) brightness(2);
    }
  }
  &:hover {
    background-color: var(--primary-color);

    .leftIcon {
      width: 15px;
      filter: sepia(1) hue-rotate(200deg) brightness(2);
    }
  }
}

.button-font {
  @include setFont('regular', 13);
}

.leftIcon {
  padding-top: 4px;
  margin-inline-end: 10px;
  padding-bottom: 4px;
}

.rightIcon {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-inline-start: 10px;
}

.btn {
  &.cancel {
    background-color: var(--hint-text) !important;
    border-color: var(--hint-text) !important;
    outline-color: var(--hint-text) !important;
    color: var(--color-white);
  }

  &.create {
    background-color: var(--submit) !important;
    border-color: var(--submit) !important;
    outline-color: var(--submit) !important;
    color: var(--color-white);
  }
}
